/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSubscribedCategory = /* GraphQL */ `
  subscription OnCreateSubscribedCategory(
    $filter: ModelSubscriptionSubscribedCategoryFilterInput
  ) {
    onCreateSubscribedCategory(filter: $filter) {
      id
      category_id
      stripe_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSubscribedCategory = /* GraphQL */ `
  subscription OnUpdateSubscribedCategory(
    $filter: ModelSubscriptionSubscribedCategoryFilterInput
  ) {
    onUpdateSubscribedCategory(filter: $filter) {
      id
      category_id
      stripe_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSubscribedCategory = /* GraphQL */ `
  subscription OnDeleteSubscribedCategory(
    $filter: ModelSubscriptionSubscribedCategoryFilterInput
  ) {
    onDeleteSubscribedCategory(filter: $filter) {
      id
      category_id
      stripe_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAskQuote = /* GraphQL */ `
  subscription OnCreateAskQuote($filter: ModelSubscriptionAskQuoteFilterInput) {
    onCreateAskQuote(filter: $filter) {
      id
      message
      Categories {
        id
        category_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buyer_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      askQuoteCategoriesId
      __typename
    }
  }
`;
export const onUpdateAskQuote = /* GraphQL */ `
  subscription OnUpdateAskQuote($filter: ModelSubscriptionAskQuoteFilterInput) {
    onUpdateAskQuote(filter: $filter) {
      id
      message
      Categories {
        id
        category_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buyer_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      askQuoteCategoriesId
      __typename
    }
  }
`;
export const onDeleteAskQuote = /* GraphQL */ `
  subscription OnDeleteAskQuote($filter: ModelSubscriptionAskQuoteFilterInput) {
    onDeleteAskQuote(filter: $filter) {
      id
      message
      Categories {
        id
        category_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buyer_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      askQuoteCategoriesId
      __typename
    }
  }
`;
export const onCreateQuote = /* GraphQL */ `
  subscription OnCreateQuote($filter: ModelSubscriptionQuoteFilterInput) {
    onCreateQuote(filter: $filter) {
      id
      message
      price
      buyer_id
      seller_id
      isAccepted
      AskQuote {
        id
        message
        Categories {
          id
          category_name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        buyer_id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        askQuoteCategoriesId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      quoteAskQuoteId
      __typename
    }
  }
`;
export const onUpdateQuote = /* GraphQL */ `
  subscription OnUpdateQuote($filter: ModelSubscriptionQuoteFilterInput) {
    onUpdateQuote(filter: $filter) {
      id
      message
      price
      buyer_id
      seller_id
      isAccepted
      AskQuote {
        id
        message
        Categories {
          id
          category_name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        buyer_id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        askQuoteCategoriesId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      quoteAskQuoteId
      __typename
    }
  }
`;
export const onDeleteQuote = /* GraphQL */ `
  subscription OnDeleteQuote($filter: ModelSubscriptionQuoteFilterInput) {
    onDeleteQuote(filter: $filter) {
      id
      message
      price
      buyer_id
      seller_id
      isAccepted
      AskQuote {
        id
        message
        Categories {
          id
          category_name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        buyer_id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        askQuoteCategoriesId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      quoteAskQuoteId
      __typename
    }
  }
`;
export const onCreateBilling = /* GraphQL */ `
  subscription OnCreateBilling($filter: ModelSubscriptionBillingFilterInput) {
    onCreateBilling(filter: $filter) {
      id
      stripeId
      stripeBillingEmail
      claimed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateBilling = /* GraphQL */ `
  subscription OnUpdateBilling($filter: ModelSubscriptionBillingFilterInput) {
    onUpdateBilling(filter: $filter) {
      id
      stripeId
      stripeBillingEmail
      claimed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteBilling = /* GraphQL */ `
  subscription OnDeleteBilling($filter: ModelSubscriptionBillingFilterInput) {
    onDeleteBilling(filter: $filter) {
      id
      stripeId
      stripeBillingEmail
      claimed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateReplies = /* GraphQL */ `
  subscription OnCreateReplies($filter: ModelSubscriptionRepliesFilterInput) {
    onCreateReplies(filter: $filter) {
      id
      replies
      commentsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateReplies = /* GraphQL */ `
  subscription OnUpdateReplies($filter: ModelSubscriptionRepliesFilterInput) {
    onUpdateReplies(filter: $filter) {
      id
      replies
      commentsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteReplies = /* GraphQL */ `
  subscription OnDeleteReplies($filter: ModelSubscriptionRepliesFilterInput) {
    onDeleteReplies(filter: $filter) {
      id
      replies
      commentsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateComments = /* GraphQL */ `
  subscription OnCreateComments($filter: ModelSubscriptionCommentsFilterInput) {
    onCreateComments(filter: $filter) {
      id
      comments
      postID
      Replies {
        items {
          id
          replies
          commentsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateComments = /* GraphQL */ `
  subscription OnUpdateComments($filter: ModelSubscriptionCommentsFilterInput) {
    onUpdateComments(filter: $filter) {
      id
      comments
      postID
      Replies {
        items {
          id
          replies
          commentsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteComments = /* GraphQL */ `
  subscription OnDeleteComments($filter: ModelSubscriptionCommentsFilterInput) {
    onDeleteComments(filter: $filter) {
      id
      comments
      postID
      Replies {
        items {
          id
          replies
          commentsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost($filter: ModelSubscriptionPostFilterInput) {
    onCreatePost(filter: $filter) {
      id
      message
      userID
      Comments {
        items {
          id
          comments
          postID
          Replies {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost($filter: ModelSubscriptionPostFilterInput) {
    onUpdatePost(filter: $filter) {
      id
      message
      userID
      Comments {
        items {
          id
          comments
          postID
          Replies {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost($filter: ModelSubscriptionPostFilterInput) {
    onDeletePost(filter: $filter) {
      id
      message
      userID
      Comments {
        items {
          id
          comments
          postID
          Replies {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateChatMessages = /* GraphQL */ `
  subscription OnCreateChatMessages(
    $filter: ModelSubscriptionChatMessagesFilterInput
  ) {
    onCreateChatMessages(filter: $filter) {
      id
      text
      chatID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateChatMessages = /* GraphQL */ `
  subscription OnUpdateChatMessages(
    $filter: ModelSubscriptionChatMessagesFilterInput
  ) {
    onUpdateChatMessages(filter: $filter) {
      id
      text
      chatID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteChatMessages = /* GraphQL */ `
  subscription OnDeleteChatMessages(
    $filter: ModelSubscriptionChatMessagesFilterInput
  ) {
    onDeleteChatMessages(filter: $filter) {
      id
      text
      chatID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat($filter: ModelSubscriptionChatFilterInput) {
    onCreateChat(filter: $filter) {
      id
      seller_id
      buyer_id
      ChatMessages {
        items {
          id
          text
          chatID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat($filter: ModelSubscriptionChatFilterInput) {
    onUpdateChat(filter: $filter) {
      id
      seller_id
      buyer_id
      ChatMessages {
        items {
          id
          text
          chatID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat($filter: ModelSubscriptionChatFilterInput) {
    onDeleteChat(filter: $filter) {
      id
      seller_id
      buyer_id
      ChatMessages {
        items {
          id
          text
          chatID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCategories = /* GraphQL */ `
  subscription OnCreateCategories(
    $filter: ModelSubscriptionCategoriesFilterInput
  ) {
    onCreateCategories(filter: $filter) {
      id
      category_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCategories = /* GraphQL */ `
  subscription OnUpdateCategories(
    $filter: ModelSubscriptionCategoriesFilterInput
  ) {
    onUpdateCategories(filter: $filter) {
      id
      category_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCategories = /* GraphQL */ `
  subscription OnDeleteCategories(
    $filter: ModelSubscriptionCategoriesFilterInput
  ) {
    onDeleteCategories(filter: $filter) {
      id
      category_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder($filter: ModelSubscriptionOrderFilterInput) {
    onCreateOrder(filter: $filter) {
      id
      buyer_id
      seller_id
      price
      total
      quantity
      item_id
      name
      image
      email
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder($filter: ModelSubscriptionOrderFilterInput) {
    onUpdateOrder(filter: $filter) {
      id
      buyer_id
      seller_id
      price
      total
      quantity
      item_id
      name
      image
      email
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder($filter: ModelSubscriptionOrderFilterInput) {
    onDeleteOrder(filter: $filter) {
      id
      buyer_id
      seller_id
      price
      total
      quantity
      item_id
      name
      image
      email
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateCart = /* GraphQL */ `
  subscription OnCreateCart($filter: ModelSubscriptionCartFilterInput) {
    onCreateCart(filter: $filter) {
      id
      buyer_id
      quantity
      tem_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateCart = /* GraphQL */ `
  subscription OnUpdateCart($filter: ModelSubscriptionCartFilterInput) {
    onUpdateCart(filter: $filter) {
      id
      buyer_id
      quantity
      tem_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteCart = /* GraphQL */ `
  subscription OnDeleteCart($filter: ModelSubscriptionCartFilterInput) {
    onDeleteCart(filter: $filter) {
      id
      buyer_id
      quantity
      tem_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateItem = /* GraphQL */ `
  subscription OnCreateItem($filter: ModelSubscriptionItemFilterInput) {
    onCreateItem(filter: $filter) {
      id
      name
      description
      price
      quantity
      image
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateItem = /* GraphQL */ `
  subscription OnUpdateItem($filter: ModelSubscriptionItemFilterInput) {
    onUpdateItem(filter: $filter) {
      id
      name
      description
      price
      quantity
      image
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteItem = /* GraphQL */ `
  subscription OnDeleteItem($filter: ModelSubscriptionItemFilterInput) {
    onDeleteItem(filter: $filter) {
      id
      name
      description
      price
      quantity
      image
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      name
      description
      email
      sub
      role
      storeName
      Items {
        items {
          id
          name
          description
          price
          quantity
          image
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Posts {
        items {
          id
          message
          userID
          Comments {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Orders {
        items {
          id
          buyer_id
          seller_id
          price
          total
          quantity
          item_id
          name
          image
          email
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activeLeadSubscription
      leadSubscriptionBillingEmail
      AskQuotes {
        items {
          id
          message
          Categories {
            id
            category_name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          buyer_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          askQuoteCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SubscribedCategories {
        items {
          id
          category_id
          stripe_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      name
      description
      email
      sub
      role
      storeName
      Items {
        items {
          id
          name
          description
          price
          quantity
          image
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Posts {
        items {
          id
          message
          userID
          Comments {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Orders {
        items {
          id
          buyer_id
          seller_id
          price
          total
          quantity
          item_id
          name
          image
          email
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activeLeadSubscription
      leadSubscriptionBillingEmail
      AskQuotes {
        items {
          id
          message
          Categories {
            id
            category_name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          buyer_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          askQuoteCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SubscribedCategories {
        items {
          id
          category_id
          stripe_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      name
      description
      email
      sub
      role
      storeName
      Items {
        items {
          id
          name
          description
          price
          quantity
          image
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Posts {
        items {
          id
          message
          userID
          Comments {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Orders {
        items {
          id
          buyer_id
          seller_id
          price
          total
          quantity
          item_id
          name
          image
          email
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activeLeadSubscription
      leadSubscriptionBillingEmail
      AskQuotes {
        items {
          id
          message
          Categories {
            id
            category_name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          buyer_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          askQuoteCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SubscribedCategories {
        items {
          id
          category_id
          stripe_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
