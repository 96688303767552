import React, { useEffect, useState } from 'react'
import UpdateUser from '../components/UpdateUser'
import { useAuthContext } from '../contexts/AuthContext'
import { DataStore, Storage } from 'aws-amplify'
import { Order } from '../models'
import { useNavigate } from 'react-router-dom'
import { PriceFormatter } from '../utils/PriceFormatter'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ChatList = () => {

  const { dbUser } = useAuthContext()
  const {sub} = useAuthContext();
  const [chat_list, setChatList] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    getAllItems()
  }, [])

  const getAllItems = async () => {
    console.log(dbUser)
    if(dbUser.role === 'seller'){
        const chat_list = (await DataStore.query(Order, (item) => item.seller_id.eq(sub))).sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))
        const fetchOrdersImage = await Promise.all(
            JSON.parse(JSON.stringify(chat_list))
            .map(async order => {
                const image = await Storage.get(order.image)
                order.S3image = image
            return order
        }))
        setChatList(fetchOrdersImage)
    } else{
        const chat_list = (await DataStore.query(Order, (item) => item.buyer_id.eq(sub))).sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))
        const fetchOrdersImage = await Promise.all(
            JSON.parse(JSON.stringify(chat_list))
            .map(async order => {
                const image = await Storage.get(order.image)
                order.S3image = image
            return order
        }))
        setChatList(fetchOrdersImage)
    }
    
  }

  if (!chat_list) {
    return <>No Chat yet</>
  }

  return (
    <div>
      {
        dbUser === undefined && <UpdateUser
          showClose={false}
          close={() => (console.log(dbUser))}
        />
      }

        <div class="py-10 h-screen bg-gray-300 px-2">
            <div class="max-w-md mx-auto bg-gray-100 shadow-lg rounded-lg overflow-hidden md:max-w-lg">
                <div class="md:flex">
                    <div class="w-full p-4">
                        <div class="relative"> <input type="text" class="w-full h-12 rounded focus:outline-none px-3 focus:shadow-md" placeholder="Search..."/> <i class="fa fa-search absolute right-3 top-4 text-gray-300"></i> </div>
                        {chat_list?.map((chat, index) => (
                                <ul>
                                <li onClick={() => navigate(`/chat/${chat?.id}`)} class="flex justify-between items-center bg-white mt-2 p-2 hover:shadow-lg rounded cursor-pointer transition">
                                    <div class="flex ml-2"> <img src={chat.S3image} alt="" width="40" height="40" class="rounded-full"/>
                                        <div class="flex flex-col ml-2"> <span class="font-medium text-black">{ dbUser?.role === 'seller' ? chat.seller_id : chat.buyer_id }</span> <span class="text-sm text-gray-400 truncate w-32"></span> </div>
                                        <div class="flex flex-col ml-2"> <span class="font-medium text-black">{chat.name}</span> <span class="text-sm text-gray-400 truncate w-32">May be yes</span> </div>
                                    </div>
                                    <div class="flex flex-col items-center"> <span class="text-gray-300">11:26</span> <i class="fa fa-star text-green-400"></i> </div>
                                </li>
                            </ul>
                         ))}  
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ChatList;