
import { useAuthContext } from '../contexts/AuthContext'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DataStore, Storage } from 'aws-amplify'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Item } from '../models'

const ViewCategoryItems = () => {
    const {id} = useParams();
    const { dbUser } = useAuthContext()
    const [item_lists, setItemLists] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        getAllItems()
    }, [])

    const getAllItems = async () => {
        console.log(dbUser)
        const allItems =await DataStore.query(Item, (item)=> item.categoriesID.eq(id))
        console.log(allItems,"all itemsss")
        const products = await Promise.all(JSON.parse(JSON.stringify(allItems)).map(async product => {
          const image = await Storage.get(product.image)
          product.S3image = image
          return product
        })
        )
        setItemLists(products)
    }

    if (!item_lists) {
        return <>Loading....</>
    }
    return (
        <div>
          <div className="m-5 md:m-10 mt-24 p-1 md:p-10 bg-white rounded-3xl">
    
            <div className="bg-white">
              <div className="mx-auto max-w-2xl py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8 pb-24">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Categories</h2>
    
                <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                  {item_lists?.map((product, index) => (
                    <div
                      key={product?.id} className="group cursor-pointer shadow-md"
                      onClick={() => navigate(`/item/${product?.id}`)}
                    >
                    <div className="min-h-80 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-none hover:opacity-75 lg:aspect-none lg:h-80">
                        <img
                        loading="lazy"
                        src={product.image}
                        className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                        /> 

                        <LazyLoadImage
                            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                            width="100%"
                            height = "100%"
                            effect="blur"
                            src={product.S3image}
                        />
                   </div>
                    <div className="mt-4 flex justify-between space-x-2 p-2">
                    <div>
                        <h1 className="text-lg text-gray-700">
                        {product?.name}
                        </h1>
                        {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
                    </div>
                    {/* <p className="text-sm font-medium text-gray-900">&#8358;{PriceFormatter(product?.price)}</p> */}
                    </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )

}

export default ViewCategoryItems