/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSubscribedCategory = /* GraphQL */ `
  mutation CreateSubscribedCategory(
    $input: CreateSubscribedCategoryInput!
    $condition: ModelSubscribedCategoryConditionInput
  ) {
    createSubscribedCategory(input: $input, condition: $condition) {
      id
      category_id
      stripe_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSubscribedCategory = /* GraphQL */ `
  mutation UpdateSubscribedCategory(
    $input: UpdateSubscribedCategoryInput!
    $condition: ModelSubscribedCategoryConditionInput
  ) {
    updateSubscribedCategory(input: $input, condition: $condition) {
      id
      category_id
      stripe_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSubscribedCategory = /* GraphQL */ `
  mutation DeleteSubscribedCategory(
    $input: DeleteSubscribedCategoryInput!
    $condition: ModelSubscribedCategoryConditionInput
  ) {
    deleteSubscribedCategory(input: $input, condition: $condition) {
      id
      category_id
      stripe_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAskQuote = /* GraphQL */ `
  mutation CreateAskQuote(
    $input: CreateAskQuoteInput!
    $condition: ModelAskQuoteConditionInput
  ) {
    createAskQuote(input: $input, condition: $condition) {
      id
      message
      Categories {
        id
        category_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buyer_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      askQuoteCategoriesId
      __typename
    }
  }
`;
export const updateAskQuote = /* GraphQL */ `
  mutation UpdateAskQuote(
    $input: UpdateAskQuoteInput!
    $condition: ModelAskQuoteConditionInput
  ) {
    updateAskQuote(input: $input, condition: $condition) {
      id
      message
      Categories {
        id
        category_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buyer_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      askQuoteCategoriesId
      __typename
    }
  }
`;
export const deleteAskQuote = /* GraphQL */ `
  mutation DeleteAskQuote(
    $input: DeleteAskQuoteInput!
    $condition: ModelAskQuoteConditionInput
  ) {
    deleteAskQuote(input: $input, condition: $condition) {
      id
      message
      Categories {
        id
        category_name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      buyer_id
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      askQuoteCategoriesId
      __typename
    }
  }
`;
export const createQuote = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      id
      message
      price
      buyer_id
      seller_id
      isAccepted
      AskQuote {
        id
        message
        Categories {
          id
          category_name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        buyer_id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        askQuoteCategoriesId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      quoteAskQuoteId
      __typename
    }
  }
`;
export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote(
    $input: UpdateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    updateQuote(input: $input, condition: $condition) {
      id
      message
      price
      buyer_id
      seller_id
      isAccepted
      AskQuote {
        id
        message
        Categories {
          id
          category_name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        buyer_id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        askQuoteCategoriesId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      quoteAskQuoteId
      __typename
    }
  }
`;
export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote(
    $input: DeleteQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    deleteQuote(input: $input, condition: $condition) {
      id
      message
      price
      buyer_id
      seller_id
      isAccepted
      AskQuote {
        id
        message
        Categories {
          id
          category_name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        buyer_id
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        askQuoteCategoriesId
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      quoteAskQuoteId
      __typename
    }
  }
`;
export const createBilling = /* GraphQL */ `
  mutation CreateBilling(
    $input: CreateBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    createBilling(input: $input, condition: $condition) {
      id
      stripeId
      stripeBillingEmail
      claimed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBilling = /* GraphQL */ `
  mutation UpdateBilling(
    $input: UpdateBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    updateBilling(input: $input, condition: $condition) {
      id
      stripeId
      stripeBillingEmail
      claimed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBilling = /* GraphQL */ `
  mutation DeleteBilling(
    $input: DeleteBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    deleteBilling(input: $input, condition: $condition) {
      id
      stripeId
      stripeBillingEmail
      claimed
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReplies = /* GraphQL */ `
  mutation CreateReplies(
    $input: CreateRepliesInput!
    $condition: ModelRepliesConditionInput
  ) {
    createReplies(input: $input, condition: $condition) {
      id
      replies
      commentsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReplies = /* GraphQL */ `
  mutation UpdateReplies(
    $input: UpdateRepliesInput!
    $condition: ModelRepliesConditionInput
  ) {
    updateReplies(input: $input, condition: $condition) {
      id
      replies
      commentsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReplies = /* GraphQL */ `
  mutation DeleteReplies(
    $input: DeleteRepliesInput!
    $condition: ModelRepliesConditionInput
  ) {
    deleteReplies(input: $input, condition: $condition) {
      id
      replies
      commentsID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createComments = /* GraphQL */ `
  mutation CreateComments(
    $input: CreateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    createComments(input: $input, condition: $condition) {
      id
      comments
      postID
      Replies {
        items {
          id
          replies
          commentsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateComments = /* GraphQL */ `
  mutation UpdateComments(
    $input: UpdateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    updateComments(input: $input, condition: $condition) {
      id
      comments
      postID
      Replies {
        items {
          id
          replies
          commentsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteComments = /* GraphQL */ `
  mutation DeleteComments(
    $input: DeleteCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    deleteComments(input: $input, condition: $condition) {
      id
      comments
      postID
      Replies {
        items {
          id
          replies
          commentsID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      message
      userID
      Comments {
        items {
          id
          comments
          postID
          Replies {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      message
      userID
      Comments {
        items {
          id
          comments
          postID
          Replies {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      message
      userID
      Comments {
        items {
          id
          comments
          postID
          Replies {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatMessages = /* GraphQL */ `
  mutation CreateChatMessages(
    $input: CreateChatMessagesInput!
    $condition: ModelChatMessagesConditionInput
  ) {
    createChatMessages(input: $input, condition: $condition) {
      id
      text
      chatID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatMessages = /* GraphQL */ `
  mutation UpdateChatMessages(
    $input: UpdateChatMessagesInput!
    $condition: ModelChatMessagesConditionInput
  ) {
    updateChatMessages(input: $input, condition: $condition) {
      id
      text
      chatID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatMessages = /* GraphQL */ `
  mutation DeleteChatMessages(
    $input: DeleteChatMessagesInput!
    $condition: ModelChatMessagesConditionInput
  ) {
    deleteChatMessages(input: $input, condition: $condition) {
      id
      text
      chatID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      seller_id
      buyer_id
      ChatMessages {
        items {
          id
          text
          chatID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      seller_id
      buyer_id
      ChatMessages {
        items {
          id
          text
          chatID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      seller_id
      buyer_id
      ChatMessages {
        items {
          id
          text
          chatID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCategories = /* GraphQL */ `
  mutation CreateCategories(
    $input: CreateCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    createCategories(input: $input, condition: $condition) {
      id
      category_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCategories = /* GraphQL */ `
  mutation UpdateCategories(
    $input: UpdateCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    updateCategories(input: $input, condition: $condition) {
      id
      category_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCategories = /* GraphQL */ `
  mutation DeleteCategories(
    $input: DeleteCategoriesInput!
    $condition: ModelCategoriesConditionInput
  ) {
    deleteCategories(input: $input, condition: $condition) {
      id
      category_name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      buyer_id
      seller_id
      price
      total
      quantity
      item_id
      name
      image
      email
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      buyer_id
      seller_id
      price
      total
      quantity
      item_id
      name
      image
      email
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      buyer_id
      seller_id
      price
      total
      quantity
      item_id
      name
      image
      email
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCart = /* GraphQL */ `
  mutation CreateCart(
    $input: CreateCartInput!
    $condition: ModelCartConditionInput
  ) {
    createCart(input: $input, condition: $condition) {
      id
      buyer_id
      quantity
      tem_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart(
    $input: UpdateCartInput!
    $condition: ModelCartConditionInput
  ) {
    updateCart(input: $input, condition: $condition) {
      id
      buyer_id
      quantity
      tem_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart(
    $input: DeleteCartInput!
    $condition: ModelCartConditionInput
  ) {
    deleteCart(input: $input, condition: $condition) {
      id
      buyer_id
      quantity
      tem_id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      name
      description
      price
      quantity
      image
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      name
      description
      price
      quantity
      image
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      name
      description
      price
      quantity
      image
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      description
      email
      sub
      role
      storeName
      Items {
        items {
          id
          name
          description
          price
          quantity
          image
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Posts {
        items {
          id
          message
          userID
          Comments {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Orders {
        items {
          id
          buyer_id
          seller_id
          price
          total
          quantity
          item_id
          name
          image
          email
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activeLeadSubscription
      leadSubscriptionBillingEmail
      AskQuotes {
        items {
          id
          message
          Categories {
            id
            category_name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          buyer_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          askQuoteCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SubscribedCategories {
        items {
          id
          category_id
          stripe_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      description
      email
      sub
      role
      storeName
      Items {
        items {
          id
          name
          description
          price
          quantity
          image
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Posts {
        items {
          id
          message
          userID
          Comments {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Orders {
        items {
          id
          buyer_id
          seller_id
          price
          total
          quantity
          item_id
          name
          image
          email
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activeLeadSubscription
      leadSubscriptionBillingEmail
      AskQuotes {
        items {
          id
          message
          Categories {
            id
            category_name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          buyer_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          askQuoteCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SubscribedCategories {
        items {
          id
          category_id
          stripe_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      description
      email
      sub
      role
      storeName
      Items {
        items {
          id
          name
          description
          price
          quantity
          image
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Posts {
        items {
          id
          message
          userID
          Comments {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Orders {
        items {
          id
          buyer_id
          seller_id
          price
          total
          quantity
          item_id
          name
          image
          email
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      activeLeadSubscription
      leadSubscriptionBillingEmail
      AskQuotes {
        items {
          id
          message
          Categories {
            id
            category_name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          buyer_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          askQuoteCategoriesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SubscribedCategories {
        items {
          id
          category_id
          stripe_id
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
