import React, { useEffect, useState } from 'react'
import { CommentProvider } from "./commentContext"
import Core from "./components/Core"
import './styles.css'

const Comments = () => {
return(
    <CommentProvider>
        <Core />
    </CommentProvider>
);
 
}

export default Comments