import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import { DataStore, Storage } from 'aws-amplify'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { API } from "aws-amplify";
import {  Categories } from '../models';
import { Autocomplete } from '@aws-amplify/ui-react';
import {withAuthenticator} from "@aws-amplify/ui-react";
import Button from '@mui/material/Button';

const SubscribeAndQuote = () => {
    const [categories, setCategories] = useState([])
    const [currentCategoryIDValue, setCurrentCategoryIDValue] = useState(undefined);

    useEffect(() => {
        getAllItems()
      }, [])
    
    const getAllItems = async () => {
        const categories = await (await DataStore.query(Categories)).sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))
        setCategories(categories)
    }

    const handleBuySubscription = () => {

    }

    return(
        <div display="flex" >
            <div className="m-5 md:m-10 mt-24 p-1 md:p-10 bg-white rounded-3xl">
                <Box
                component="form"
                sx={{'& .MuiTextField-root': { m: 1, width: '25ch' },}}
                noValidate
                autoComplete="off">
                    <div>
                        <Autocomplete label="Categories" 
                            options={
                            categories.filter((r, i, arr) =>
                            arr.findIndex((member) => member?.id === r?.id) === i)
                                .map((r) => ({id: r?.id,label: r.category_name,}))}
                                onSelect={({ id, label }) => {setCurrentCategoryIDValue(id)}}/>
                            <Box display="flex"  justifyContent="space-around" sx={{ mt: 3 }} >
                                <Button variant="contained" sx={{ padding: '12px 24px', mt: 2 }} > Subscribe </Button> 
                            </Box>
                         
                        {/* <stripe-buy-button
                            buy-button-id="buy_btn_1OlEyrI6deDjsZeHd3cHNREm"
                            publishable-key="pk_test_51NoKykI6deDjsZeHifxdliBaZeVnM870TPy496IEQLcnpJ4CL6sPnbOjHULjc2Z8HUlsuKIPx2VidcOTw2eVsdgB00CkBjRIWl"
                        >

                        </stripe-buy-button> */}
                    </div>
                </Box>
            </div>
        </div>
        
    );
};

export default withAuthenticator(SubscribeAndQuote);