import {withAuthenticator} from "@aws-amplify/ui-react"
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {DataStore, Storage} from 'aws-amplify'
import { ChatMessages } from '../models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, InputGroup, FormControl, Button } from 'react-bootstrap';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import intlFormatDistance from "date-fns/intlFormatDistance";

function Chat({ user, signOut }) {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [chats, setChats] = useState([]);
    const {id} = useParams();
    React.useEffect(() => {
      async function fetchChats() {
        const allChats = await DataStore.query(ChatMessages, (chat)=> chat.chatID.eq(id))
        setChats(allChats);
      }
      fetchChats();
    }, []);
    const handleInputChange = (e) => {
      setNewMessage(e.target.value);
    };
    const handleSendMessage = async () => {
      if (newMessage.trim() === '') return;
      await DataStore.save(
        new ChatMessages({
          text: newMessage,
          chatID: id,
        })
      );
       setMessages([...messages, { text: newMessage }]);
    };
    return (
      <div className="flex justify-center items-center h-screen w-full">
        <div className={`w-3/4 flex flex-col`}>
        {chats
            .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
            .map((chat) => (
              <div
                key={chat.id}
                className={`flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 w-3/4 my-2 ${
                  chat.email === user.attributes.email && "self-end bg-gray-200"
                }`}
              >
                <div>
                  <div className="flex justify-between gap-x-4">
                    <div className="py-0.5 text-xs leading-5 text-gray-500">
                      {/* <span className="font-medium text-gray-900">
                        {chat.email.split("@")[0]}
                      </span>{" "} */}
                    </div>
                    <time
                      dateTime="2023-01-23T15:56"
                      className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                    >
                      {intlFormatDistance(new Date(chat.createdAt), new Date())}
                    </time>
                  </div>
                  <p className="text-sm leading-6 text-gray-500">{chat.text}</p>
                </div>
              </div>
            ))}
          <div>
            <div className="relative mt-2 flex items-center">
              <input
                type="text"
                name="search"
                id="search"
                onKeyUp={async (e) => {
                  if (e.key === "Enter") {
                    // Remove this line
                    // setChats(e.target.value);

                    // Add these
                    handleSendMessage() 
                    e.target.value = "";
                  }
                }}
                className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                <Button variant="primary" onClick={handleSendMessage}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default withAuthenticator(Chat);