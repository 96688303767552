// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { SubscribedCategory, AskQuote, Categories, Quote, Billing, Replies, Comments, Post, ChatMessages, Chat, Order, Cart, Item, User } = initSchema(schema);

export {
  SubscribedCategory,
  AskQuote,
  Categories,
  Quote,
  Billing,
  Replies,
  Comments,
  Post,
  ChatMessages,
  Chat,
  Order,
  Cart,
  Item,
  User
};