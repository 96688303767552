import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import { DataStore, Storage } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import 'react-lazy-load-image-component/src/effects/blur.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {  Categories } from '../models';
import { Autocomplete } from '@aws-amplify/ui-react';

import AuthContextProvider from '../contexts/AuthContext';
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import {withAuthenticator} from "@aws-amplify/ui-react";
import Button from '../components/Button';
import { AskQuote } from '../models';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// TODO: Add a quote to list block

const GetQuote = () => {

  const { sub } = useAuthContext()
  //const [category_list, setCategory_list] = useState(null)
  const [items_list, setItems_list] = useState(null)
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([])
  const [quote, setQuote] = useState('')
  const [currentCategoryIDValue, setCurrentCategoryIDValue] = useState(undefined);

  console.log(sub)

  useEffect(() => {
    getAllItems()
  }, [])

  const getAllItems = async () => {
    const categories = await (await DataStore.query(Categories)).sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))
    // console.log(allItems,"all itemsss")
    // const products = await Promise.all(JSON.parse(JSON.stringify(allItems)).map(async product => {
    //   const image = await Storage.get(product.image)
    //   product.S3image = image
    //   return product
    // })
    // )
    setCategories(categories)
  }
  React.useEffect(() => {
    async function fetchQuote() {
      const allQuotes = await API.graphql({
        query: queries.askQuotesByUserID,
        variables: {
          userID: sub
        }
      });
      console.log(allQuotes);
      //setQuote(allQuotes.data.quotesByUserID.items);
    }
    fetchQuote();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };


  const handleCreateQuote = async () => {
    console.log(sub)
    console.log(sub)
    console.log(quote)
    if(quote.length <= 0){
      alert("Emplty Quote ")
    }else{
      await DataStore.save( new AskQuote(
        {
          userID: sub,
          buyer_id: sub,
          message: quote,
          askQuoteCategoriesId: currentCategoryIDValue
        }
      ));
      setQuote('');
      alert('Quote Submitted !! Hang tight for the best deals')
      
    } 
    }

  return (
    <div display="flex" >
      <div className="m-5 md:m-10 mt-24 p-1 md:p-10 bg-white rounded-3xl">
          {/* <h2 className="text-2xl font-bold tracking-tight text-gray-900">Get some help</h2> */}
          <div className="mx-auto max-w-2xl py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
          <AuthContextProvider>
          <form className="m-auto mt-1">
          <Box
            component="form"
            className=" max-w-xl"
            sx={{'& .MuiTextField-root': { m: 1, width: '100ch' },}}
            noValidate
            autoComplete="off">
          <p>Make sure to add as much details as possible to get the right quote. Please do not include any sensitive information</p>
          <br/>
          <br/>
           <TextField
              id="outlined-textarea"
              label="I need a quote for"
              multiline
              onChange={(e) => setQuote(e.target.value)}
              rows={5}
            />
            <br/>
            <br/>
            <p>Add a Label</p>
            <br/>
            <Autocomplete label="Categories" 
              options={
              categories.filter((r, i, arr) =>
              arr.findIndex((member) => member?.id === r?.id) === i)
                  .map((r) => ({id: r?.id,label: r.category_name,}))}
                  onSelect={({ id, label }) => {setCurrentCategoryIDValue(id)}}/>
            <br/>
            <Button
                            color="white"
                            bgColor='var(--main)'
                            text="Get a Quote"
                            borderRadius="10px"
                            width="full"
                            onClick={handleCreateQuote}
                        />


          </Box>
          </form>
          </AuthContextProvider>
          </div>
                
          </div>
        </div>
  );
};
export default withAuthenticator(GetQuote);