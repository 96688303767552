import { Avatar, Card, Stack, ThemeProvider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { useParams } from 'react-router-dom'
import CommentContext from "../commentContext";
import theme from "../styles";
import EditableCommentField from "./Reusable/Comment/EditableCommentField";
import SendButton from "./Reusable/Buttons/BgButtons/SendButton";
import { Button } from "@mui/material";
import * as mutations from "../../../graphql/mutations";

import { API } from "aws-amplify";

const AddComment = () => {
  const { IMGOBJ } = useContext(CommentContext);
  const [commentTxt, setCommentTxt] = useState("");
  const {id} = useParams();

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <Box sx={{ p: "15px" }}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            {/* <Avatar
              src={IMGOBJ.juliusomo}
              variant="rounded"
              alt="user-avatar"
            /> */}
            <EditableCommentField
              commentText={commentTxt}
              setCommentText={setCommentTxt}
              placeHolder="Add a comment"
            />
          <Button
              size="large"
              sx={{
                bgcolor: "custom.moderateBlue",
                color: "neutral.white",
                p: "8px 25px",
                "&:hover": {
                  bgcolor: "custom.lightGrayishBlue",
                },
              }}
              onClick = {async(e) => {
                
                await API.graphql({
                  query: mutations.createComments,
                  variables: {
                    input: {
                      comments: commentTxt,
                      postID: id,
                    },
                  },
                });
              }}
              >Send</Button>
          </Stack>
        </Box>
      </Card>
    </ThemeProvider>
  );
};

export default AddComment;
