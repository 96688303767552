import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import { Order } from '../models'
import { API, graphqlOperation, Predicates } from "aws-amplify";
import { DataStore, Storage } from 'aws-amplify'
import OrdersItem from '../components/OrdersItem'
import * as queries from "../graphql/queries";
import {withAuthenticator} from "@aws-amplify/ui-react";

import { DataGrid } from '@mui/x-data-grid';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const columns = [
    { id: 'id', label: 'ID', minWidth: 170 },
    {
      id: 'name',
      label: 'name',
      minWidth: 170,
      align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'email',
        label: 'email',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
      },
    
  ];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = async (event) => {
    const stripe = await loadStripe('pk_test_51NoKykI6deDjsZeHifxdliBaZeVnM870TPy496IEQLcnpJ4CL6sPnbOjHULjc2Z8HUlsuKIPx2VidcOTw2eVsdgB00CkBjRIWl')
    const { error } = await stripe.redirectToCheckout({
        lineItems: [{
          price: 'price_1OcusdI6deDjsZeHhtQoHedC',
          quantity: 1
        }],
        clientReferenceId: "2121",
        customerEmail: "raj@gmail.com",
        mode: 'subscription',
        successUrl: 'http://localhost:3001/orders',
        cancelUrl: 'http://localhost:3001/cancel'
      })
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <div>
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
    </div>
  );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  

const SellerOrder2 = () => {

const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(5);
const { sub, email } = useAuthContext()
const [order_list, setorder_list] = useState(null)

// Avoid a layout jump when reaching the last page with empty rows.
const emptyRows =
page > 0 ? Math.max(0, (1 + page) * rowsPerPage - order_list.length) : 0;

const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};


    console.log(sub)
    useEffect(() => {
      getOrders()
    }, [])

    const getOrders = async () =>{
    const myOrders =  (await 
        DataStore.query(Order, 
            (item) => 
            item.seller_id.eq(sub))).sort((x, y) => new Date(x.createdAt) - new Date(y.createdAt))

    // const variables = {
    //     filter: {
    //         seller_id: {eq: sub}
    //     }
    // };
    // const myOrders2 =  await API.graphql({
    //     query: queries.listOrders,
    //     variables: variables
    //     });

    // const myOrders2 =   await API.graphql(
    //         graphqlOperation(queries.listOrders, {
    //           filter: {
    //             // Replace 'fieldName' and 'fieldValue' with your actual condition
    //             seller_id: { eq: sub },
    //           },
    //         })
    //       );
    // const myOrders = myOrders2.data.listOrders.items
    console.log(myOrders)
    const fetchOrdersImage = await Promise.all(
        JSON.parse(JSON.stringify(myOrders))
        .map(async order => {
            const image = await Storage.get(order.image)
            order.S3image = image
        return order
    }))

    setorder_list(myOrders)
        
    }
    
    if(!order_list){
        return <>Loading...</>
    }
    return (
        <Box sx={{ height: 400, width: '100%' }}>
             <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
      <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? order_list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : order_list
          ).map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell style={{ width: 160 }} align="right">
                {row.name}
              </TableCell>
              <TableCell style={{ width: 160 }} align="right">
                {row.email}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={order_list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </Box>
    )
}

export default withAuthenticator(SellerOrder2);