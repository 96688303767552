import React from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import { Item } from '../../models'
import './styles.css'
import {DataStore, Storage} from 'aws-amplify'
import { useState } from 'react'
import { useEffect } from 'react'
import { PriceFormatter } from '../../utils/PriceFormatter'
import { User } from '../../models'
import { useAuthContext } from '../../contexts/AuthContext'
import { Cart, Order } from '../../models'
import {withAuthenticator} from "@aws-amplify/ui-react"

//MUI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const ViewItem = () => {

    const {id} = useParams();

    const { sub, dbUser } = useAuthContext()

    const [email , setEmail] = useState('')
    const [item, setItem] = useState(null)
    const [seller, setSeller] = useState(null)
    const [open, setOpen] = useState(false);

  const navigate = useNavigate()
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubscribe = async () => {
    console.log({
        buyer_id: sub,
        seller_id: item.userID,
        price: parseFloat(item.price),
        quantity : 1.0,
        total: 1.0,
        name: item.name,
        image: item.image,
        item_id: item.id,
        email: email
    });
    
    // const retrieved_item = await DataStore.query(Order, (c) => c.and(c => [
    //     c.buyer_id.eq(sub),
    //     c.seller_id.eq(item.userID),
    //     c.id.eq(item.id)
    //   ]));
    
    // if(retrieved_item){
    //     await DataStore.save(new Order({
    //         buyer_id: sub,
    //         seller_id: item.userID,
    //         price: parseFloat(item.price),
    //         quantity : 1.0,
    //         total: 1.0,
    //         name: item.name,
    //         image: item.image,
    //         item_id: item.id
    //     })).then(async (res) => {setOpen(false);})
    // }

    await DataStore.save(new Order({
        buyer_id: sub,
        seller_id: item.userID,
        price: parseFloat(item.price),
        quantity : 1.0,
        total: 1.0,
        email: email,
        name: item.name,
        image: item.image,
        item_id: item.id,
        userID: sub
    })).then(async (res) => {setOpen(false);})
  };

    useEffect(() => {
      DataStore.query(Item, id).then(setItem)
    }, [])

    useEffect(() => {
        getItem()
      }, [])

      const [images, setimages] = useState('')

      const getItem = async () => {
        const itemInfo = await DataStore.query(Item, id)
        setItem(itemInfo)
           console.log(itemInfo)
          const image = await Storage.get(itemInfo.image)
          setimages(image)

          const sellerInfo = await DataStore.query(User, (user)=> user.sub.eq(itemInfo.userID))
          setSeller(sellerInfo[0])
      }

      const addToCart = async () => {

        await DataStore.save(new Cart({ buyer_id : sub, quantity: 1, tem_id: item.id }))
            .then((res) => {
                console.log(res, "resresres")
            })
            .catch((err) => {
                console.log(err, "errerrerr")
            })
    }

    if(!item){
        return <>Loading...</>
    }

    return (
        <div>
            <div className="card">
                <div className="card__title">
                    <div className="icon" 
                        onClick={()=>navigate(-1)}
                    >
                        <IoArrowBack size={24}/>
                    </div>
                    <h3>Products</h3>
                </div>
                <div className="card__body">
                    <div className="half">
                        <div className="featured_text">
                            <h1 style={{fontSize:'32px'}}>{item?.name}</h1>
                            {item.price ? <p className="price">CAD {PriceFormatter(item?.price)}</p>: null }
                        </div>
                        <div className="image">
                            <img src={images} style={{maxWidth:'1050px'}} alt="" />
                        </div>
                    </div>
                    <div className="other_half">
                        <div>
                            <div className="description">
                                <p>{item?.description}</p>
                            </div>
                            {/* <div className="stock">{item?.quantity} In stock</div>
                            <div className="reviews">
                                <div className="stars">
                                    <AiFillStar size={20} />
                                    <AiFillStar size={20} />
                                    <AiFillStar size={20} />
                                    <AiOutlineStar size={20} />
                                    <AiOutlineStar size={20} />
                                    <span>(64 reviews)</span>
                                </div>
                            </div> */}
                        </div>
                        <div className="card__footer">
                            <div className="recommend">
                                <p>Ad Created by</p>
                                <h3>{seller?.storeName}</h3>
                            </div>
                            {
                                <div className="action">
                                    <button type="button" onClick={handleClickOpen}>Send Interest</button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">
                                        <DialogTitle id="alert-dialog-title">
                                            {"Thanks for showing your Interest in this Post"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                We'll make sure to inform the local business vendor about your interest 
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                                value={email}
                                                onChange={(e) => {setEmail(e.target.value)}}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                        <Button onClick={handleClose}>Close</Button>
                                        <Button onClick={handleSubscribe}>Send My Interest</Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withAuthenticator(ViewItem);
