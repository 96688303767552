import React from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiShoppingCart, FiLogIn } from 'react-icons/fi';
import { MdKeyboardArrowDown } from 'react-icons/md';

import avatar from '../assets/avatar.webp';
import Cart from '../components/Cart';
import UserProfile from '../components/UserProfile'

import { useStateContext } from '../contexts/ContextProvider';
import { useAuthContext } from '../contexts/AuthContext';
import AuthContextProvider from '../contexts/AuthContext';

// Amplify 
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';


const Navbar = () => {
  const  {dbUser} = useAuthContext()


    const NavButton = ({ customFunc, icon, color, dotColor , text}) => (
        <button
            type="button"
            onClick={() => customFunc()}
            style={{ color }}
            className="relative text-xl rounded-full p-3 hover:bg-light-gray"
        >
            <span
                style={{ background: dotColor }}
                className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
            />
            {icon}
            {text}
        </button>
    );

    const { activeMenu, setActiveMenu, handleClick, isClicked } = useStateContext();

    const handleActiveMenu = () => setActiveMenu(!activeMenu);

    return (
        <div className="flex justify-between items-center p-2 md:ml-6 md:mr-6 relative">
            
            <div className='hidden md:block'>
                <NavButton title="Menu" customFunc={handleActiveMenu} color='var(--main)' icon={<AiOutlineMenu />} />
            </div>
            <div className="flex">
                {
                    // dbUser?
                    // <div>
                    //     {/* <NavButton title="Cart" dotColor="var(--main)" customFunc={() => handleClick('cart')} color="var(--main)" icon={<FiShoppingCart />} /> */}
                    //     <div
                    //         className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                    //         onClick={() => handleClick('userProfile')}
                    //      >
                    //     {/* <img
                    //         className="rounded-full w-8 h-8"
                    //         src={avatar}
                    //         alt="user-profile"
                    //     /> */}
                        
                    //     <p>
                    //         <span className="text-gray-400 text-14">Hello,</span>{' '}
                    //         <span className="text-gray-400 font-bold ml-1 text-14">
                    //             {dbUser?.name.split(" ")[0]}
                    //         </span>
                    //     </p>
                    
                    //     <MdKeyboardArrowDown className="text-gray-400 text-14" />
                    //     </div>
                    // </div>
                    // :
                    <div>
                        <NavButton title="My Profile" dotColor="var(--main)" customFunc={() => handleClick('login')} color="var(--main)" icon={<FiLogIn />} text='My Profile' />
                    </div>
                }
                {/* {isClicked.cart && (<Cart />)} */}
                {isClicked.login && (
                    <Authenticator>
                    {({ signOut, user }) => (
                        // <main>
                        // <h1>Hello {user.username}</h1>
                        // <button onClick={signOut}>Sign out</button>
                        // </main>
                    <AuthContextProvider>
                        <p>
                        {/* <span className="text-gray-400 text-14">Hello,</span>{' '}
                        <span className="text-gray-400 font-bold ml-1 text-14">
                            {dbUser?.name.split(" ")[0]}
                        </span> */}
                        <UserProfile />
                       </p>
                    </AuthContextProvider>
                    )}
                    </Authenticator>
                )}
                {isClicked.userProfile && (<UserProfile />)}
            </div>
        </div>
    );
};

export default Navbar
