import { createContext, useState } from "react";
import { useParams } from 'react-router-dom'
import amyrobson from "./images/avatars/image-amyrobson.png";
import maxblagun from "./images/avatars/image-maxblagun.png";
import ramsesmiron from "./images/avatars/image-ramsesmiron.png";
import juliusomo from "./images/avatars/image-juliusomo.png";

import React from 'react'
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as subscriptions from "../../graphql/subscriptions";
import { useAuthContext } from '../../contexts/AuthContext'

const CommentContext = createContext();
const IMGOBJ = { amyrobson, maxblagun, ramsesmiron, juliusomo };
// const { comments, currentUser } = data;
const currentUser = {
  "image": { 
    "png": "./images/avatars/image-juliusomo.png",
    "webp": "./images/avatars/image-juliusomo.webp"
  },
  "username": "juliusomo"
}

export function CommentProvider({ children }) {
  
  const {id} = useParams();
  const { sub } = useAuthContext()
  const [comments, setComments] = React.useState([]);
  React.useEffect(() => {
    async function fetchComments() {
      const allComments = await API.graphql({
        query: queries.commentsByPostID,
        variables: {
          postID: id
        }
      });
      console.log(allComments.data.commentsByPostID.items);
      setComments(allComments.data.commentsByPostID.items);
    }
    fetchComments();

    const sub = API.graphql(
      graphqlOperation(subscriptions.onCreateComments)
    ).subscribe({
      next: ({ provider, value }) =>
        setComments((prev) => [...prev, value.data.onCreateComments]),
      error: (err) => console.log(err),
    });

    return () => sub.unsubscribe();
  }, []);
  //const [commentSection, setCommentSection] = useState(posts);
  const addComment = (id) => {};
  // const addComment = (data) => {
  //   setCommentSection([
  //     ...commentSection,
  //     {
  //       id: Math.floor(Math.random() * 10000),
  //       content: data,
  //       createdAt: "Just now",
  //       score: 0,
  //       replies: [],
  //       user: { username: "juliusomo" },
  //     },
  //   ]);
  // };

  const deleteComment = (commentId) => {
    setComments(
      comments.filter((comment) => comment.id !== commentId)
    );
  };

  const addReply = (replyContent) => {};

  const deleteReply = (id) => {};

  return (
    <CommentContext.Provider
      value={{
        currentUser,
        comments,
        IMGOBJ,
        addComment,
        deleteComment,
      }}
    >
      {children}
    </CommentContext.Provider>
  );
}

export default CommentContext;
