import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { Auth, DataStore, Hub } from 'aws-amplify'
import { User } from "./../models";
import { AiOutlineConsoleSql } from "react-icons/ai";

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [role, setRole] = useState(null)
    const [dbUser, setDbuser] = useState(null)
    const [sub , setSub] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        const fetchUser = async()=> {
            try{
                const user = await Auth.currentAuthenticatedUser()
                setUser(user)
                console.log(user)
                const sub = user.username
                await DataStore.query(User, (u)=> u.sub.eq(sub))
                    .then((user) => {
                        console.log("User")
                        console.log(user)
                        setRole(user[0].role)
                        setDbuser(user[0])
                        console.log(dbUser)
                        console.log("Role Set")
                    })
                setSub(user.username)
            }
            catch(error){
                console.log("Login error", error)
                setUser(null)
                setRole(null)
                setSub(null)
                setDbuser(null)
            }
            setLoading(false);
        };
        fetchUser();
        const listener = Hub.listen('auth', ({ payload }) => {
            if (payload.event === 'signIn') {
                fetchUser();  // Re-fetch role on sign-in
            }else {
                setRole('')
            }
          });
      
          return () => {
            Hub.remove('auth', listener);
          };
    }, [])

    const login = async (username, password) => {
        try{

            const user = await Auth.signIn(username, password);
            setUser(user);
            // TODO Need to add Auth

        }catch(error){

        }
    }

    const logout = async () =>{
        try{
            await Auth.signOut();
            setUser(null);
            setRole(null);
        } catch(error){
            console.log("Logout error", error)
        }
    }


    // const [state, setState] = useState({
    //     currentUser: {},
    //     isLoaded: false
    //   })
    // const sub = authUser?.attributes?.sub
    // useEffect(() => {
    //     checker()
    // }, [])

    
    // const checker = async () => {
    //     //get authenticated user information
    //     console.log("called checker")
        
    //     const componentDidMount = () => {
    //         console.log("called updateCurrentUser on componentDidMount")
    //         updateCurrentUser()
    //         Hub.listen('auth', this);
    //     }
    //     const onHubCapsule = (capsule) => {

    //         const { channel, payload } = capsule;
    //         if (channel === 'auth' && payload.event !== 'signIn') {
    //             console.log("called updateCurrentUser on onHubCapsule")
    //             updateCurrentUser()
    //         }
    //     }
    //     const updateCurrentUser = async (user) => {
    //         console.log("in updateCurrentUser")
    //         // if (user) {
    //         //     console.log("user present")
    //         //     console.log(user)
    //         //     setState({ currentUser: user })
    //         //     setauthUser(user)
    //         //     return
    //         // }
    //         try {
    //           console.log("Auth User set")
    //           const user = await Auth.currentAuthenticatedUser()
    //           //setState({ currentUser: user, isLoaded: true })
    //           console.log("Auth User set")
    //           console.log(user)
    //           setauthUser(user)
    //           console.log(authUser)
    //           const sub = user?.sub || user?.attributes?.sub;
    //           console.log(sub)
    //           await DataStore.query(User, (u) => u.sub("eq", sub))
    //                 .then((user) => {
    //                     console.log("User")
    //                     console.log(user)
    //                     setDbuser(user[0])
    //                 })
    //         } catch (err) {
    //           //this.setState({ currentUser: null, isLoaded: true })
    //         }
    //       }
        //   updateCurrentUser()
    
        // try{
        //     await Auth.currentAuthenticatedUser({ bypassCache: true }).then( async (res)=>{
        //         console.log(res)
        //         setauthUser(res)
        //         //query user information from database
        //         await DataStore.query(User, (user) => user.sub.eq(res?.attributes?.sub))
        //             .then((user) => {
        //                 console.log("User")
        //                 console.log(user)
        //                 setDbuser(user[0])
        //             })
        //     })
        // } catch(e){
        //     console.log("user not authenticated")
        //     return
        // }
        
    //}

    return (

        <AuthContext.Provider
            value={{
                user, 
                role, 
                dbUser,
                login, 
                logout,
                sub, 
                setDbuser,
                loading
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
export default AuthContextProvider;

export const useAuthContext = () => useContext(AuthContext)