import React from 'react';
import { useNavigate } from 'react-router-dom';

import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {withAuthenticator} from "@aws-amplify/ui-react";

import AuthContextProvider, { useAuthContext } from '../contexts/AuthContext'

const MyPost = () => {
    const context = useAuthContext();
    // // const sub = context.sub
    console.log(context);
    const [posts, setPosts] = React.useState([]);
    const navigate = useNavigate();


    React.useEffect(() => {
        async function fetchPosts() {
            
        const sub = context.user.username
          console.log(sub)
          const allPosts = await API.graphql({
            query: queries.postsByUserID,
            variables: {
                userID: sub
            }
          });
          console.log(allPosts)
          console.log(allPosts.data.postsByUserID.items);
          setPosts(allPosts.data.postsByUserID.items);
        }
        fetchPosts();
      }, []);
     

    return(
        <AuthContextProvider>
<Box sx={{ minWidth: 100 }}>
            {
                posts?.map((post, index) => (
                    <div style={{margin: '3%'}}>
                        <Card variant="outlined"  sx={{ maxWidth: 600 }} >
                            <React.Fragment>
                                <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    My Post
                                </Typography>
                                <Typography variant="body2">
                                    {post.message}
                                </Typography>
                                </CardContent>
                                <CardActions>
                                <Button size="small" onClick={() => navigate(`/comments/${post?.id}`)}>Comments</Button>
                                </CardActions>
                            </React.Fragment>
                        </Card>
                    </div>
                ))
            }

        </Box>
        </AuthContextProvider>
        
    );

}

export default withAuthenticator(MyPost);