import React, { useEffect, useState } from 'react'
import UpdateUser from '../components/UpdateUser'
import { useAuthContext } from '../contexts/AuthContext'
import { DataStore, Storage } from 'aws-amplify'
import { Post } from '../models'
import { useNavigate } from 'react-router-dom'
import { PriceFormatter } from '../utils/PriceFormatter'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Item } from '../models'

import AuthContextProvider from '../contexts/AuthContext';
import { API } from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Amplify, Hub } from "@aws-amplify/core";
import * as queries from "../graphql/queries";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Dashboard = () => {

  const { sub } = useAuthContext()
  //const [category_list, setCategory_list] = useState(null)
  const [items_list, setItems_list] = useState(null)
  const [post, setPost] = useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    async function fetchItems() {
      console.log(sub)
      const allItemsRaw = await API.graphql({
        query: queries.listItems,
      });
      console.log("all items")
      console.log(allItemsRaw.data.listItems.items);
      const allItems = allItemsRaw.data.listItems.items;
      const products = await Promise.all(JSON.parse(JSON.stringify(allItems)).map(async product => {
        const image = await Storage.get(product.image)
        product.S3image = image
        return product
      }))
      console.log("all products")
      console.log(products);
      setItems_list(products)
      
    }
    fetchItems();
 
    // const removeListener = Hub.listen("datastore", async (capsule) => {

    //   const {
    //     payload: { event, data },
    //   } = capsule;
  
    //   console.log("DataStore event", event, data);
  
    //   if (event === "ready") {

    //     try{
    //       console.log("Getting item")
    //       const allItems =  (await DataStore.query(Item)).sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt))
      
    //       console.log(allItems,"all itemsss")
    //       const products = await Promise.all(JSON.parse(JSON.stringify(allItems)).map(async product => {
    //         const image = await Storage.get(product.image)
    //         product.S3image = image
    //         return product
    //       })
    //       )
    //       setItems_list(products)
    //       console.log(products)
    
    //     }catch (error) {
    //         setError(error);
    //         console.log("Got error")
    //       } finally {
    //         setLoading(false);
    //         console.log("Got finally")
    //       }

    //   }


    // });
    // // Start the DataStore, this kicks-off the sync process.
    // DataStore.start();
 
    // return () => {
    //   removeListener();
    // };
  }, [])

  

  const handleCreatePost = async () => {
    console.log(sub)
    if(sub == null){
      setOpen(true);
    }else{
      console.log(sub)
      console.log(post)
      if(post.length <= 0){
        alert("Emplty post")
      }else{
        await DataStore.save( new Post(
          {
            userID: sub,
            message: post
          }
        ));
      }
      
    }
    }
    
  if (!items_list) {
    return <>Loading....</>
  }

  return (
    <div display="flex" >
          <div className="m-5 md:m-10 mt-24 p-1 md:p-10 bg-white rounded-3xl">
          {/* <h2 className="text-2xl font-bold tracking-tight text-gray-900">Get some help</h2> */}
          <div className="mx-auto max-w-2xl py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8">
          <AuthContextProvider>
          <Box
            component="form"
            className=" max-w-xl"
            sx={{'& .MuiTextField-root': { m: 1, width: '100ch' },}}
            noValidate
            autoComplete="off">
         
            <TextField
              id="outlined-textarea"
              label="Get some community help"
              multiline
              onChange={(e) => setPost(e.target.value)}
              rows={5}
            />
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{"LogIn"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                  {open && (
                    <Authenticator>
                    {({ signOut, user }) => (
                        // <main>
                        // <h1>Hello {user.username}</h1>
                        // <button onClick={signOut}>Sign out</button>
                        // </main>
              
                        <p>
                        {/* <span className="text-gray-400 text-14">Hello,</span>{' '}
                        <span className="text-gray-400 font-bold ml-1 text-14">
                            {dbUser?.name.split(" ")[0]}
                        </span> */}
                        {setOpen(false)}
                       </p>
                    
                    )}
                    </Authenticator>
                  )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
            <Box display="flex"  justifyContent="space-around">

              <Button variant="contained" onClick={handleCreatePost}> POST </Button> 
          
              <Button variant="contained" onClick={() => navigate(`/All-Post`)}> View POST </Button> 
            </Box>
           
          </Box>
          </AuthContextProvider>
          </div>

          </div>
          
          <div className="m-5 md:m-10 mt-24 p-1 md:p-10 bg-white rounded-3xl">
    
            <div className="bg-white">
              <div className="mx-auto max-w-2xl py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-8 pb-24">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">Feed</h2>
    
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {items_list?.map((product, index) => (
                    <div class="flex flex-col bg-white shadow-lg rounded-lg p-6">
                    <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      image={product.S3image}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {product?.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {product?.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" onClick={() => navigate(`/item/${product?.id}`)}>Learn More</Button>
          
                    </CardActions>
                  </Card>
                  </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}
export default Dashboard