import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom';

import {  AiOutlineHome, AiOutlineShoppingCart, AiOutlineEye,AiOutlineFilter } from 'react-icons/ai';
import { MdOutlineInterests } from "react-icons/md";
import { TbCategoryPlus } from "react-icons/tb";
import { FiShoppingBag } from 'react-icons/fi';
import { RiContactsLine, RiChat1Line } from 'react-icons/ri';
import { useStateContext } from '../contexts/ContextProvider';
import { useEffect, useContext } from "react";

import { useAuthContext } from '../contexts/AuthContext';

const Sidebar = () => {

  const { activeMenu } = useStateContext();
  const { role } = useAuthContext();
  console.log(role)

  useEffect(() => {
    console.log("Role changed:", role);
    // This effect will run every time role changes
  }, [role]);
  
  const handleCloseSideBar = () => {
    if (activeMenu !== undefined) {
    }
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-gray-200  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';


  const links =  role === 'seller' ?
      [
        { name: 'home',  icon: <AiOutlineHome /> },
        { name: 'My-Curiosity-Clicks', icon: <AiOutlineEye />, },
        { name: 'My-Post',  icon:<AiOutlineFilter />,},
        { name: 'All-Post',  icon:<AiOutlineFilter />,},
        {  name: 'my-store',  icon: <FiShoppingBag /> },
        { name: 'Get-Quote',  icon:<AiOutlineFilter />,},
        {  name: 'Leads',  icon: <AiOutlineShoppingCart />, },
        {  name: 'Subscribe-And-Quote',  icon: <AiOutlineShoppingCart />, },
      ]
      :
      [
        { name: 'home',  icon: <AiOutlineHome />,},
        { name: 'My-Curiosity-Clicks', icon: <AiOutlineEye />, },
        { name: 'My-Post',  icon:<AiOutlineFilter />,},
        { name: 'All-Post',  icon:<AiOutlineFilter />,},
        { name: 'Get-Quote',  icon:<AiOutlineFilter />,},
      ]
      

  return (
    // <div className='block sm:hidden'>
    <div className=" ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
              <div className="mt-10 ">
                <p className="text-gray-400 dark:text-gray-400 m-3 mb-6">
                    <img src="logo.png" alt="Logo"></img>
                </p>

              <div>
                
                {links.map((link) => (
                    
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? "var(--main)" : '',
                    })}
                    className={({ isActive }) => (isActive ? activeLink : normalLink)}
                  >
                    {link.icon}
                    <span className="capitalize">{link.name}</span>
                  </NavLink>
                ))}
              </div>
          </div>
        </>
      )}
    </div>
    // </div> 
  )
}

export default Sidebar
